import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import aboutMe from 'assets/illustrations/about_me.svg';

import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={aboutMe} alt="I’m Kristin and I’m a Software Tester!" />
        </Thumbnail>
        <Details theme={theme}>
          <h2>About me</h2>
          <p>I'm currently studying to become a software tester at KYH, Gothenburg.</p>
          <p>I'm passionate about test and strongly driven by curiosity - what happens if I just... ^^</p>
          <p>I enjoy puzzles and problem solving, some of which have materialised into the projects presented below.</p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
