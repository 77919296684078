import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import happenings from 'assets/illustrations/projects/happenings.png';
import diceGame from 'assets/illustrations/projects/dicegame.png';
import rainbow from 'assets/illustrations/projects/rainbow.png';
import decisionApp from 'assets/illustrations/projects/decisionApp.png';

import { Link } from 'gatsby';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const ProjectSummary = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <a
            href="https://last-night-in-sweden.stinisson.se"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Go to happenings live site"
          >
            <img src={happenings} alt="Happenings - a map over current police events" />
          </a>
        </Thumbnail>
        <Details theme={theme}>
          <h2>Last Night in Sweden</h2>
          <p>
            <a
              href="https://last-night-in-sweden.stinisson.se"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to happenings live site"
            >
              Last Night in Sweden
            </a>{' '}
            is a website answering the question "what happened last night in Sweden" by compiling current police events
            obtained through the Swedish Police Authority's open data together with official crime statistics of
            shootings and explosions.
          </p>
          <p>
            Police stations and their opening hours are displayed on a map with the possibility to filter open and
            closed stations and the stations' provided services.
          </p>
          <p>
            <a
              href="https://last-night-in-sweden.stinisson.se"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to happenings live site"
            >
              Last Night in Sweden
            </a>{' '}
            is built with Node.js, Express and interactive Leaflet maps.
          </p>
        </Details>
      </SkillsWrapper>




      <SkillsWrapper as={Container}>
        <Thumbnail>
          <a
            href="http://play-dice-game.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Go to dice game live site"
          >
            <img src={diceGame} alt="Dice game" />
          </a>
        </Thumbnail>
        <Details theme={theme}>
          <h2>Dice Game</h2>
          <p>
            <a
              href="http://play-dice-game.herokuapp.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to dice game live site"
            >
              Dice Game
            </a>{' '}
            is a website for recruiters and recruits to play an <a
                href="https://en.wikipedia.org/wiki/Petals_Around_the_Rose"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to dice game live site"
            >
               analytical reasoning game
            </a>{' '}



            remotely.
            Dice Game is a playful way to evaluate communication and analytical thinking.
          </p>
          <p>
            The game begins with the dice being rolled. The total number of dots is calculated according to a
            secret rule. The task of the recruits is to figure out the secret rule. By continuously reporting
            their thinking to the recruiter, the recruiter gets an insight into the recruits' way of thinking
            and how they approach the task. The task is solved by submitting four correct answers in a row.
          </p>
          <p>
            A complete CI/CD pipeline with automatic testing and static application security testing is up and
            running on GitLab.
          </p>
        </Details>
      </SkillsWrapper>

      <SkillsWrapper as={Container}>
        <Thumbnail>
          <a
              href="https://rainbow.stinisson.se"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to rainbow game live site"
          >
            <img src={rainbow} alt="Rainbow" />
          </a>
        </Thumbnail>
        <Details theme={theme}>
          <h2>Rainbow</h2>
          <p>
            <a
                href="https://rainbow.stinisson.se"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to rainbow live site"
            >
              Rainbow
            </a>{' '}
            is a website which illustrates the Bitcoin price evolution assuming a long term logarithmic growth.
          </p><p>
            The price is displayed with <a
                href="https://www.chartjs.org"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to chartjs.org"
            > Chart.js</a>, the backend is built with <a
                href="https://nodejs.org"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to nodejs.org"
            > Node.js</a> and price data is collected and stored in <a
                href="https://www.mongodb.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to mongodb.com"
            > MongoDB</a>.
          </p>
        </Details>
      </SkillsWrapper>

      <SkillsWrapper as={Container}>
        <Thumbnail>
            <img src={decisionApp} alt="Poker decision support" />
        </Thumbnail>
        <Details theme={theme}>
          <h2>DecisionApp</h2>
          <p>DecisionApp - a poker game decision support site written in C#/.NET as a Blazor Server App.</p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
