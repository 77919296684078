import React from 'react';
import { Container } from 'components/common';
import dots from 'assets/illustrations/dots_animation/dots_animation.png';
import { Wrapper, Thumbnail, Details } from './styles';

export const ConnectTheDots = () => (
  <Wrapper as={Container} id="connect-the-dots">
    <Thumbnail>
      <img id="connectTheDots" src={dots} alt="Connect the dots" />
    </Thumbnail>
  </Wrapper>
);
