import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/panda_animation/panda_animation.png';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Hi There!</h1>
          <h4>I’m Kristin and I’m a Software Tester student!</h4>
          <Button as={Link} href="mailto:kristin.karlsson@protonmail.com">
            Say Hi!
          </Button>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m Kristin and I’m a Software Tester!" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
