import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail, Books, Book } from './styles';
import books from './books.json';

export const Reading = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Details theme={theme}>
          <h1>Currently reading</h1>
        </Details>
        <Books>
          {books.map(({ id, title, author, link, image }) => (
            <Book>
              <a
                key={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Currently reading ${title}`}
              >
                <img src={image} alt={image} />
              </a>
              <p>{title}</p>
              <p2>{author}</p2>
            </Book>
          ))}
        </Books>
      </SkillsWrapper>
    </Wrapper>
  );
};
